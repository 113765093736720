
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/7-1.png'),
      w: 806,
      h: 720,
      title: '1. Go to your document checklist. Upload each file.',
    },
    {
      src: require('./img/7-6.png'),
      w: 805,
      h: 410,
      title: '1. Go to your document checklist. Upload each file.',
    },
  ]
}
