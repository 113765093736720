
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/8-2.png'),
      w: 805,
      h: 720,
      title: '1. Final application status',
    },
  ]
}
