
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/1.png'),
      w: 805,
      h: 605,
      title:
        "1. Go to your IRCC account's home page. Navigate to Continue an application you haven't submitted. Click Continue application.",
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/2.png'),
      w: 805,
      h: 590,
      title: '2. Complete questionnaire.',
    },
    {
      src: require('./img/3.png'),
      w: 805,
      h: 710,
      title: '2. Complete questionnaire.',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/4-1.png'),
      w: 805,
      h: 700,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/4-4.png'),
      w: 805,
      h: 455,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-1.png'),
      w: 800,
      h: 693,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-2.png'),
      w: 805,
      h: 655,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-3.png'),
      w: 805,
      h: 575,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-4.png'),
      w: 806,
      h: 543,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-5.png'),
      w: 805,
      h: 500,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-6.png'),
      w: 804,
      h: 655,
      title: '3. Complete Permanent Residency application forms.',
    },
    {
      src: require('./img/5-7.png'),
      w: 805,
      h: 580,
      title: '3. Complete Permanent Residency application forms.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/6-1.png'),
      w: 806,
      h: 700,
      title: '4. Go to your completed Permanent Residency application. Click Continue.',
    },
  ]
  images5: GalleryImage[] = [
    {
      src: require('./img/7-1.png'),
      w: 806,
      h: 720,
      title: '5. Get your document checklist.',
    },
    {
      src: require('./img/7-2.png'),
      w: 806,
      h: 690,
      title: '5. Get your document checklist.',
    },
  ]
}
