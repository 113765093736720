
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/7-2.png'),
      w: 806,
      h: 690,
      title: 'Optional Documents -> Client Information',
    },
  ]
}
