import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import { GuideStepGroupId } from 'shared-entities'

const steps: StepViewItem[] = [
  {
    id: GuideStepGroupId.PrApplication,
    title: 'Submit Permanent Residence application',
    subItems: [
      {
        id: 100,
        title: 'Get your document checklist',
        component: require('./steps/ItaGetDocumentChecklist.vue').default,
      },
      {
        id: 101,
        title: 'Upload supporting documents',
        component: require('./steps/ItaUploadSupportingDocuments.vue').default,
      },
      {
        id: 102,
        title: 'Submit application',
        component: require('./steps/ItaSubmiPRApplication.vue').default,
      },
      {
        id: 103,
        title: 'Application status updates',
        component: require('./steps/ItaApplicationStatusUpdates.vue').default,
      },
    ],
  },
  {
    id: GuideStepGroupId.PrApplicationSupportingDocuments,
    title: 'Supporting documents guide',
    subItems: [
      {
        id: 200,
        title: 'Translation of documents',
        component: require('./steps/ItaGuideTranslationOfDocuments.vue').default,
      },
      {
        id: 201,
        title: 'Language test results',
        component: require('./steps/ItaGuideLanguage.vue').default,
      },
      {
        id: 205,
        title: 'Medical Exam',
        component: require('./steps/ItaGuideMedicalExam.vue').default,
      },
      {
        id: 210,
        title: 'Education',
        component: require('./steps/ItaGuideEducation.vue').default,
      },
      {
        id: 225,
        title: 'Police Certificates',
        component: require('./steps/ItaGuidePoliceCertificates.vue').default,
      },
      {
        id: 230,
        title: 'Employment Records',
        component: require('./steps/ItaGuideEmploymentRecords.vue').default,
      },
      {
        id: 235,
        title: 'Offer of Employment',
        component: require('./steps/ItaGuideEmploymentOffer.vue').default,
      },
      {
        id: 240,
        title: 'Letter of Employment',
        component: require('./steps/ItaGuideEmploymentLetter.vue').default,
      },
      {
        id: 241,
        title: 'Provincial or territorial certificate of qualification',
        component: require('./steps/ItaGuideCertificateOfQualification.vue').default,
      },
      {
        id: 245,
        title: 'Passport/Travel documents',
        component: require('./steps/ItaGuidePassport.vue').default,
      },
      {
        id: 246,
        title: 'National ID',
        component: require('./steps/ItaGuideNationalID.vue').default,
      },
      {
        id: 247,
        title: 'United States permanent resident card',
        component: require('./steps/ItaGuideUSPR.vue').default,
      },
      {
        id: 250,
        title: 'Family Member Proof of Status',
        component: require('./steps/ItaGuideFamilyMemberProofOfStatus.vue').default,
      },
      {
        id: 251,
        title:
          'Proof of PR/Citizenship, residency in Canada and relationship of relative (including sibling)',
        component: require('./steps/ItaGuideProofOfRelative.vue').default,
      },
      {
        id: 275,
        title: 'Birth Registration/Certificate',
        component: require('./steps/ItaGuideBirthCertificate.vue').default,
      },
      {
        id: 276,
        title: 'Legal Name Change Documents',
        component: require('./steps/ItaGuideNameChange.vue').default,
      },
      {
        id: 280,
        title: 'Marriage certificate',
        component: require('./steps/ItaGuideMarriageCertificate.vue').default,
      },
      {
        id: 281,
        title: 'Evidence of common-law union and cohabitation',
        component: require('./steps/ItaGuideEvidenceOfUnionCohabitation.vue').default,
      },
      {
        id: 282,
        title: 'Divorce certificate and legal separation agreement',
        component: require('./steps/ItaGuideDivorceCertificate.vue').default,
      },
      {
        id: 283,
        title: 'Death certificate (for widowed)',
        component: require('./steps/ItaGuideDeathCertificate.vue').default,
      },
      {
        id: 284,
        title: 'Adoption certificate',
        component: require('./steps/ItaGuideAdoptionCertificate.vue').default,
      },
      {
        id: 285,
        title: 'Proof of Means of Financial Support',
        component: require('./steps/ItaGuideProofOfMeans.vue').default,
      },
      {
        id: 286,
        title: 'Use of a Representative form',
        component: require('./steps/ItaGuideUseOfRepresentative.vue').default,
      },
      {
        id: 290,
        title: 'Digital Photo',
        component: require('./steps/ItaGuideDigitalPhoto.vue').default,
      },
      {
        id: 295,
        title: 'Additional Documents',
        component: require('./steps/ItaGuideAdditionalDocuments.vue').default,
      },
    ],
  },
]

export default steps
